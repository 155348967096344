//get the fuck outta here bro, just use the website like normal

import React, { useState, useEffect } from 'react';

const TraceIP = ({ target, onComplete }) => {
  const traceData = {
    'gateway': [
      'Hop 1: 192.168.1.1 - INTERNAL NETWORK - Response: Stable',
      'Hop 2: 10.10.0.1 - RESEARCH SUBNET - Latency detected: 350ms',
      'Hop 3: 172.16.0.254 - VOIDSPACE NODE - Error: Unstable connection',
      'Hop 4: 203.0.113.10 - ██████████████████████ - ███████████████',
      'Hop 5: 198.51.100.42 - INITIATING FAILSAFE',
    ],
    'controller': [
      'Hop 1: 192.168.2.1 - CENTRAL CONTROL SYSTEM - Response: Stable',
      'Hop 2: 10.20.0.5 - QCG - Error: Signal instability',
      'Hop 3: 172.16.1.5 - Manual override required at Security Node Alpha',
      'Hop 4: 203.0.113.15 - TEMPORAL SYNC NODE - Signal divergence: Reality time-stretch detected.',
      'Hop 5: 198.51.100.30 - CHRONO-SPATIAL GATEWAY - Warning: Unauthorized entity detection.',
    ],
    'database_node': [
      'Hop 1: 192.168.3.1 - AUXILIARY DATA STORAGE - Response: Stable',
      'Hop 2: 10.30.0.12 - BACKUP NODE - Data integrity at risk: Possible corruption',
      'Hop 3: 172.16.2.100 - EXP DATA CLUSTER',
      'Hop 4: 203.0.113.55 - ACCESS DENIED: Admin privileges required',
      'Hop 5: 198.51.100.99 - FAILED EXP ARCHIVE - Data leak reported. Containment breach imminent.',
    ],
    'admin': [
      'Hop 1: 192.168.4.1 - SECURITY ADMIN NODE - Response: Stable',
      'Hop 2: 10.40.0.30 - ADMIN OVERRIDE DETECTED - Suspicious access attempt logged',
      'Hop 3: 172.16.3.200 - FAILED LOGIN ATTEMPTS: 13 from user: rbaker',
      'Hop 4: 203.0.113.99 - ALERT: Possible insider threat flagged by Security Subnet',
      'Hop 5: 198.51.100.17 - VOIDSPACE SECURITY OVERRIDE - Reality tampering detected, temporal feedback loop initiated.',
    ],
    'experiment_subnet': [
      'Hop 1: 192.168.5.1 - PRIMARY EXPERIMENT NODE - Response: Stable',
      'Hop 2: 10.50.0.22 - ANOMALOUS DATA NODE - Reality inversion detected. Signal scrambling...',
      'Hop 3: 172.16.4.77 - PHASE SHIFT GATEWAY - Temporal anomaly reported: Entity "█████████████" detected.',
      'Hop 4: 203.0.113.88 - SUBNET FIREWALL BYPASS - Integrity breach reported, signal redirected.',
      'Hop 5: 198.51.100.76 - SECURE EXPERIMENT STORAGE - Warning: Data packets from Experiment 8934.omega exceed safety thresholds.',
    ],
    'quantum_research': [
      'Hop 1: 192.168.6.1 - QUANTUM RESEARCH HUB - Response: Stable',
      'Hop 2: 10.60.0.14 - SUB-QUANTUM FREQUENCY NODE - Error: Reality flux detected, shutting down.',
      'Hop 3: 172.16.5.12 - QUANTUM ENTANGLEMENT CLUSTER - Entity feedback anomaly detected, signal collapsed.',
      'Hop 4: 203.0.113.22 - KUGELBLITZ PROTOCOL NODE - Failed activation, energy surge redirected.',
      'Hop 5: 198.51.100.44 - CONTAINMENT UNIT: VOIDMEN - Breach detected, Voidman fractions escaped.',
    ],
  };
  

  const [traceIndex, setTraceIndex] = useState(0);

  useEffect(() => {
    if (traceIndex < traceData[target].length) {
      const timeout = setTimeout(() => {
        setTraceIndex(traceIndex + 1);
      }, 1000);
      return () => clearTimeout(timeout);
    } else {
      onComplete();
    }
  }, [traceIndex, target, onComplete]);

  return (
    <>
      {traceData[target].slice(0, traceIndex).map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </>
  );
};

export default TraceIP;