//if you're reading this YOU JUST LOST THE GAME

import React, { useState, useEffect } from 'react';

const TerminalIntro = () => {
  const [history, setHistory] = useState([]);

  const introLines = [
    "==================== [ DEMIURGE INC. TERMINAL ] ====================",
    "Boot Sequence Initiated...",
    "Loading Core Systems................................... [OK]",
    "Verifying Security Clearance........................... [OK]",
    "Establishing Secure Connection......................... [OK]",
    "System Integrity Check................................. [OK]",
    "[!] WARNING: External Anomalies Detected in Voidspace Monitoring Node",
    "Welcome to the Demiurge Laboratory Network.",
    "Access is restricted to authorized personnel only.",
    "Please ensure all security protocols are adhered to at all times.",
    "> AWAITING USER AUTHENTICATION...",
    " - Type 'help' for available commands.",
    "============================================================="
  ];

  useEffect(() => {
    let isMounted = true; 

    const printLines = (index) => {
      if (isMounted && index < introLines.length) {
        setHistory((prevHistory) => [...prevHistory, introLines[index]]);
        setTimeout(() => printLines(index + 1), index < 5 ? 1000 : 500); 
      }
    };

    printLines(0); 

    return () => {
      isMounted = false; 
    };
  }, []); 

  return (
    <div className="terminal">
      {history.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </div>
  );
};

export default TerminalIntro;
