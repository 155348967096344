//why you digging aroun here?

import React from 'react';
import './Legal.css';

function Legal() {
  return (
    <div className="legal-page">
      <h2>Legal Disclaimer</h2>
      <p>
        DEMIURGE INC. is not responsible for any side effects resulting from services provided. The fabric of reality is a labyrinth of uncertainties, and venturing beyond the boundaries of your native timeline or spatiotemporal universe is not without risk. In the vast expanse of the multiverse(s), unforeseen paradoxes and entangled destinies lurk, presenting unprecedented dangers beyond imagination.
      </p>
      <p>
        Our time travel and interdimensional warping services are offered with the gravest responsibility and respect for the delicate balance of all existence. Once you step beyond the threshold, the past, present, and future intertwine, and the course of destiny may never be the same again. Choose your path wisely, for the choices you make today may echo through eternity.
      </p>

      <h3>Catastrophic Reality-Warping Events</h3>
      <p>
        DEMIURGE INC. acknowledges the potential for catastrophic reality-warping events, including but not limited to: reality fractures, timeline disintegration, universal implosions, dimension inversions, and multiversal collapses. By engaging in our services, you acknowledge that such events, while rare, may result in total annihilation of one or more realities, irreversible alteration of the fabric of existence, or the spontaneous creation of alternate dimensions where different laws of physics or morality may apply.
      </p>
      <p>
        In the event of such occurrences, DEMIURGE INC. assumes no responsibility or liability for any loss of life, sanity, existence, or continuity of being. Clients and participants waive any and all rights to sue, claim damages, or seek restitution from DEMIURGE INC. or its affiliates in the aftermath of these events. Your participation in our services is entirely voluntary and undertaken at your own risk.
      </p>

      <h3>Retrocausal Rehabilitation Services</h3>
      <p>
        If any (non-lethal/non-reality-ending) issues arise, DEMIURGE INC. offers retro-retrocausal rehabilitation services, and retro-retro-retrocausal rehabilitation services if those are ineffective. These services are designed to mitigate the effects of time anomalies and dimensional disturbances. However, please note that outcomes may vary, and some effects may be irreversible.
      </p>

      <h3>Exclusions from RealityLeak™ Guarantee</h3>
      <p>
        Self-recursion, chrono-lag, existential entanglement, reality divergence, paradoxical echoes, chronotic aberration, dimensional voiding/"erasure", entropic contagion, temporal drift & pivoting, interdimensional memory dissonance, quantum anomaly bleeding/"entanglement phantoms", causality splintering, multiversal doppelgangers, fragmentation syndrome, anachronistic reality oscillation, cognitohazardous information overload, and any other quantum conundrums are not covered under the DEMIURGE INC. RealityLeak™ guarantee.
      </p>

      <h3>Limitation of Liability</h3>
      <p>
        By utilizing DEMIURGE INC.’s services, you agree that under no circumstances shall DEMIURGE INC. or its affiliates be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if DEMIURGE INC. has been advised of the possibility of such damages) resulting from the use or the inability to use the service, unauthorized access to or alteration of your transmissions or data, statements or conduct of any third party in connection with the service, or any other matter relating to the service.
      </p>

    </div>
  );
}

export default Legal;
