import React from 'react';

const DefunctServer = () => {
  return (
    <div className="server-logs">
        <p>AVAILABLE COMMANDS: view_logs, ping_devices, restore, back</p>
    </div>
  );
};

//if you're reading this YOU JUST LOST THE GAME

export default DefunctServer;