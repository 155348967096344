import React, { useState } from 'react';
import './About.css';

const About = () => {
  const [isIntroOpen, setIsIntroOpen] = useState(true);
  const [isMissionOpen, setIsMissionOpen] = useState(true);
  const [isHistoryOpen, setIsHistoryOpen] = useState(true);
  const [isTimelineExpanded, setIsTimelineExpanded] = useState(false);
  const [isJeffOpen, setIsJeffOpen] = useState(false);
  const [isJeffVisible, setJeffVisible] = useState(false); 

  const revealJeff = () => {
    setJeffVisible(true);
  };

  const toggleTimeline = () => {
    setIsTimelineExpanded(!isTimelineExpanded);
  };

  return (
    <div className="about-container">

      <section className="about-intro">
        <h2 onClick={() => setIsIntroOpen(!isIntroOpen)}>
          {isIntroOpen} About Demiurge Inc.
        </h2>
        {isIntroOpen && (
          <p>Welcome to Demiurge Inc., a cutting-edge laboratory focused on reality modulation, dimensional tuning, and advanced experimentation. Our team of researchers and engineers is dedicated to exploring the boundaries of science and pushing the limits of what is possible in the multiverse.</p>
        )}
      </section>

      <section className="about-mission-vision">
        <h2 onClick={() => setIsMissionOpen(!isMissionOpen)}>
          {isMissionOpen} Our Mission
        </h2>
        {isMissionOpen && (
          <p>Our mission is to unlock the potential of multiversal exploration, transforming the impossible into the achievable. We are pioneers in reality warping and dimensional travel technologies. Our vision is to create a future where realities are no longer bound by conventional laws, allowing and enabling humanity to experience dimensions beyond imagination.</p>
        )}
      </section>


      <section className="about-history">
        <h2 onClick={() => setIsHistoryOpen(!isHistoryOpen)}>
          {isHistoryOpen} Our History
        </h2>
        {isHistoryOpen && (
          <div>
            <p>Our History
              The origins of Demiurge Inc. are as elusive as the dimensions we study. While traditional records suggest a founding date in 1990, our own research has uncovered anomalies indicating that the laboratory may have existed long before any formal establishment. Some of our earliest experiments appear to reference the company itself—decades before it was known or discovered. These experiments, encoded in fragments of lost timelines, hint at a self-referential loop of creation. Demiurge Inc. was not just founded—it was, in a sense, discovered… possibly even by itself. The timeline of our foundation remains uncertain, with some theorizing that Demiurge Inc. may have created its own inception point, looping back through the very multiverses we now explore. Whether a product of paradox or an artifact of dimensional manipulation, our existence is as much a mystery as the phenomena we seek to understand.
            </p>
            {!isTimelineExpanded && (
              <button className="expand-timeline-button" onClick={toggleTimeline}>
                Expand History
              </button>
            )}
            {isTimelineExpanded && (
            <div>
            <p>
              <b>1990 - Founding of Demiurge Inc.</b>: 
              While the official date of foundation was recorded as 1990, strange occurrences around this time suggest that the lab’s inception may have been retroactively seeded into the timeline. Witnesses from the era speak of buildings materializing overnight, facilities that had "always been there," but had somehow escaped notice. From the start, Demiurge Inc. attracted minds from all disciplines, many of whom couldn't recall how they found the lab, as if drawn by an unseen force.
            </p>
            <p>
              <b>1995 - The Greyfield Anomaly</b>: 
              The discovery of what is now known as the "Greyfield Anomaly" marked a significant turning point in the lab’s research. During an experiment on quantum frequency dispersion, researchers inadvertently opened a rift that led to the Greyfield - a dimensional voidspace existing in a state of quantum flux. While initial reports suggested disaster, further study indicated that the Greyfield may hold the key to understanding interdimensional travel and the very nature of time loops and the laboratory itself.
            </p>
            <p>
              <b>1996 - The Reality Stabilization Project</b>: 
              Following the Greyfield Anomaly, DEMIURGE INC. launched the Reality Stabilization Project (RSP). This initiative sought to control and harness dimensional shifts, with the ultimate goal of stabilizing entire realities. The RSP became the cornerstone of DEMIURGE INC.'s research, as the team focused on understanding the mechanisms that govern dimensional stability, preventing catastrophic collapses in newly discovered dimensions. Early efforts involved the creation of containment fields capable of isolating unstable dimensions, but as the project progressed, the team moved toward more advanced techniques of reality tethering—using anchors to keep dimensions from drifting out of sync with our known universe. This allowed DEMIURGE INC. to not only explore new dimensions safely but also to intervene in unstable realities, reinforcing their structures before they could collapse.
            </p>
            <p>
              <b>1998 - Government Contracts</b>: 
              DEMIURGE INC.’s work in dimensional manipulation did not go unnoticed. By 1998, the company had secured multiple contracts with DARPA (the Defense Advanced Research Projects Agency), aimed at exploring the potential for military applications of dimensional tuning. These projects involved the development of technology that could theoretically "phase" objects in and out of physical dimensions, leading to the creation of highly advanced, nearly invisible defense systems. The lab’s profitability soared during this period, with a substantial portion of its research being funneled into classified government programs.
            </p>
            <p>
              <b>2001 - Black Ops Weaponization Initiatives</b>: 
              In the aftermath of securing high-level contracts with the U.S. government, DEMIURGE INC. began collaborating with major weapons manufacturers. The focus shifted towards the practical application of the lab’s discoveries in the realm of military technology, including weapons systems that could disrupt enemy communications and destabilize dimensional fields. Projects like these were heavily classified, leading to speculation about DEMIURGE INC.’s deeper involvement in covert black ops projects. High profitability followed as government agencies and private defense contractors vied for access to the lab’s cutting-edge technology.
            </p>
            <p>
              <b>2008 - The Collapse Prevention Initiative</b>: 
              With the ever-present threat of universal collapse, DEMIURGE INC. spearheaded the Collapse Prevention Initiative (CPI), a (then) top-secret project aimed at preventing the disintegration of known dimensions. This period also saw the rise of Dr. Orion Vale as one of the lab’s leading minds, whose groundbreaking work on quantum fissures allowed for greater control over interdimensional frequency shifts.
            </p>
            <p>
              <b>2015 - First Contact</b>: 
              In 2015, DEMIURGE INC. made its first confirmed contact with a parallel version of Earth. The experiments that led to this discovery remain classified, but published journal articles and news reports indicate that this event fundamentally changed the way the lab (and quantum science itself) views multiverse travel. The knowledge gained from this encounter spurred further developments in dimensional overlap studies that fundamentally shifted the course of DEMIURGE INC.'s research and experimentation yet again.
            </p>
            {isJeffVisible && (
            <p>
            <b>2021 - Jeff</b>: 
              The elusive figure known only as "Jeff" came into prominence around 2021, though many colleagues (and all historical reports, financial documentation, memories, and photographs from staff events) suggest that he had been heading the lab since its inception. Jeff’s true nature remains a closely guarded secret, though his control over DEMIURGE INC.'s operations is unquestionable. Under his leadership, the lab has accelerated its efforts, exploring the mysteries of existence itself, and expanding its reach into the farthest realms of reality.
            </p>
            )}
            <p>
              <b>Present Day</b>: 
              DEMIURGE INC. stands at the forefront of multiverse exploration and manipulation. With projects spanning dimensions, the lab has become a beacon of innovation and intrigue. Yet, for all the knowledge we have gained, the greatest mystery remains: how did it all begin? Perhaps we will never know if DEMIURGE INC. was the product of human innovation, or if it has always been—outside of time, waiting to be discovered once again.
            </p>    
            <button className="expand-timeline-button" onClick={toggleTimeline}>
                {isTimelineExpanded ? 'Collapse History' : 'Expand History'}
              </button>      
            </div>
            )}
            </div>
        )}
      </section>

 {/* Oooh I wonder what this secret section is? is it lore? is it shitpost? meta-reference? who knows? */}

      <div className="hidden-icon" onClick={revealJeff}>
        <span role="img" aria-label="hidden eye" title="What lies behind the curtain?">👁️</span>
      </div>

      {isJeffVisible && (
        <section className="about-jeff">
          <h2 onClick={() => setIsJeffOpen(!isJeffOpen)}>
            {isJeffOpen} About Jeff
          </h2>
          {isJeffOpen && (
            <p>Jeff’s presence at DEMIURGE INC. is a peculiar one. One day in 2021, he simply was - the director, the founder, the guiding force behind the lab. There was no grand announcement, no transition of power. He just appeared, and everyone accepted it as fact. “Oh, Jeff? He’s always been here,” colleagues would say, their memories filled with vivid moments of Jeff leading meetings, making key decisions, and shaping the very direction of the lab since its inception. Even the very fact that he's only known by a mononym isn't seen as strange. He's just Jeff.

            His face appears in photographs from company events dating back to the lab’s earliest days, his name etched into official records and foundational documents, his oil portrait adorns the main investors' hall. Yet, there’s an eerie sense that no one quite remembers the moment he arrived, because to us, he’s always been here. It’s as though his existence, fully integrated into the lab’s history, was never in question. Jeff didn’t rise through the ranks or establish his position as director and founder. Instead, it feels as if reality itself bent to accommodate him, seamlessly integrating him into every significant event in DEMIURGE INC.’s storied past. </p>
          )}
        </section>
      )}
    </div>
  );
};

export default About;
