export function decodeBase64Unicode(str) {
    const binaryData = atob(str);
    const utf8Data = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      utf8Data[i] = binaryData.charCodeAt(i);
    }
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(utf8Data);
  }
  
  export function isBase64(str) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }
  
  export function safeDecodeBase64(content) {
    return isBase64(content) ? atob(content) : content;
  }

  //if you're reading this YOU JUST LOST THE GAME
  