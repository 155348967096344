import React from 'react';

//if you're reading this, you just LOST THE GAME

const Sidebar = ({ isSidebarShrunk, handleNavigation, loggedIn, users, inputUsername, setInputUsername, setInputPassword, handleLogout, toggleSidebar, error, handleLogin, theme, toggleTheme, openTerminal }) => (
  <div className={`sidebar ${isSidebarShrunk ? 'shrink' : ''}`}>
    
    <div className="sidebar-close-overlay" onClick={toggleSidebar}></div>
    
    <nav onClick={(e) => e.stopPropagation()}>
      {['HOME', 'NEWS', 'FAQ', 'CONTACT', 'CAREERS', 'INVESTORS', 'ABOUT', 'LEGAL'].map(item => (
        <a key={item} href="#" onClick={e => {
          e.preventDefault();
          handleNavigation(item.toLowerCase()); 
        }}>
          {item}
        </a>
      ))}
      {loggedIn && users[inputUsername]?.pages?.map(page => (
        <a key={page} href="#" onClick={e => {
          e.preventDefault();
          handleNavigation(page); 
        }}>
          {page.toUpperCase()}
        </a>
      ))}
    </nav>

    <div className="login-container">
      {loggedIn ? (
        <div>
          <p>USER: {inputUsername}</p>
          <a href="#" onClick={e => {
            e.preventDefault();
            handleLogout();
          }} className="logout-link">
            LOGOUT
          </a>
        </div>
      ) : (
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Username"
            value={inputUsername}
            onChange={e => setInputUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            onChange={e => setInputPassword(e.target.value)}
          />
          <button type="submit">LOGIN</button>
          {error && <p className="error">{error}</p>}
        </form>
      )}
    </div>

        <div className="theme-toggle">
          <button disabled={false} onClick={toggleTheme}>
              Switch to {theme === 'light' ? 'Dark' : 'Light'} Mode
          </button>
      </div>
  </div>

  
);

export default Sidebar;

//if you're reading this, you just LOST THE GAME AGAIN
