import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <p>
        We appreciate your interest in DEMIURGE INC. If you have any questions, inquiries, or require further information, please do not hesitate to reach out to us through the following contact details:
      </p>

      <h2>General Inquiries</h2>
      <p>Email: <a href="mailto:freakbait@freakbait.com?subject=DEMIURGE INC. General Inquiries" title="info@demiurge-inc.com">info@demiurge-inc.com</a></p>

      <h2>Investor Relations</h2>
      <p>Email: <a href="mailto:freakbait@freakbait.com?subject=DEMIURGE INC. Investment Inquiry&body=DEMIURGE INC.,%0A%0AI am interested in investing in your endeavors and would love more information about how I can contribute to your expansion into alternate markets.%0A%0AAmount of capital I am willing to invest:%0A%0APreferred area of investment (dimensional warfare, multiverse travel, reality manipulation, etc.):%0A%0AAny concerns about ethical practices or risks to my personal reality:%0A%0AThank you for this opportunity to partner in reshaping the boundaries of existence.%0A%0ASincerely,%0A[Your Name]" title="investors@demiurge-inc.com">investors@demiurge-inc.com</a></p>

      <h2>Report Strange Event/Dimensional Anomaly</h2>
      <p>Email: <a href="mailto:freakbait@freakbait.com?subject=DEMIURGE INC. Strange Event/Dimensional Anomaly Report&body=DEMIURGE INC.,%0A%0AI would like to report a strange event or dimensional anomaly.%0A%0AIncident Description:%0ADate and Time of Occurrence:%0ALocation of Incident (if applicable):%0ADetailed Explanation of Event:%0A%0AHas this occurred before?: [Yes/No]%0A%0AHave you ever experienced deja vu?%0A%0AAny additional information:%0A%0AThank you for investigating this matter.%0A%0ASincerely,%0A[Your Name]" title="report@demiurge-inc.com">report@demiurge-inc.com</a></p>

      <h2>Missing Persons</h2>
      <p>Email: <a href="mailto:freakbait@freakbait.com?subject=DEMIURGE INC. Missing Persons Report&body=DEMIURGE INC.,%0A%0AI would like to report a missing person who was involved in DEMIURGE INC. experimentation.%0A%0AName of the Missing Person (if still remembered):%0ALast Known Date of Contact:%0AExperiment Involved:%0ALast Known Location:%0A%0AAny unusual behavior or anomalies leading up to their disappearance:%0A%0ADo you have any theories about their current dimension or state of existence?:%0A%0AThank you for investigating this matter.%0A%0ASincerely,%0A[Your Name or Current Identity]" title="missing@demiurge-inc.com">missing@demiurge-inc.com</a></p>


      <h2>Media Inquiries</h2>
      <p>Email: <a href="mailto:freakbait@freakbait.com?subject=Media Inquiry About DEMIURGE INC.&body=DEMIURGE INC.,%0A%0AI am a member of the media and would love to learn more about your groundbreaking work. Please consider the following questions for an upcoming story:%0A%0A1. How exactly do you ensure the safety of those involved in your experiments, and what *really* happened during the Greyfield Anomaly?%0A2. Can you confirm or deny the rumors of entire teams disappearing into alternate dimensions mid-experiment?%0A3. What steps is DEMIURGE INC. taking to avoid, you know, accidentally collapsing the universe?%0A4. Should the public be concerned about the so-called 'dimensional anomalies' near your facility?%0A%0AThank you for your time.%0A%0ASincerely,%0A[Your Name, Reporter from (Media Outlet)]" title="media@demiurge-inc.com">media@demiurge-inc.com</a></p>
    </div>
  );
}

export default Contact;

//i would get an acutal email for demiurge-inc.com but I am way too broke to put money into an art project that doesn't make me any money
//but we can still have fun with it, eh?
