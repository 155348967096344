import React from 'react';
import './Header.css';
import headerImage from '../data/img/qcgheader.webp';

//if you're reading this, you just LOST THE GAME

const Header = ({ toggleSidebar }) => {
  return (
    <header className="header" onClick={toggleSidebar}>
      <img src={headerImage} alt="Demiurge Inc. Header" className="header-image" />
    </header>
  );
};

export default Header;