import React from 'react';
import './UserLanding.css';

//if you're reading this, you just LOST THE GAME

const UserLanding = ({ setActivePage, username, role }) => {
  return (
    <div className="landing-container">
      <h1>Welcome, {username}!</h1>
      <div className="menu">
        <div className="menu-item" onClick={() => setActivePage('files')}>
          <div className="icon">📁</div>
          <div className="label">FILES</div>
        </div>

        {role === 'researcher' && (
        <div className="menu-item" onClick={() => setActivePage('email')}>
          <div className="icon">📧</div>
          <div className="label">EMAIL</div>
        </div>
        )}

        <div className="menu-item" onClick={() => setActivePage('log')}>
          <div className="icon">🧪</div>
          <div className="label">EXPERIMENTS</div>
        </div>

        {role === 'admin' && (
          <div className="menu-item" onClick={() => setActivePage('cctv')}>
            <div className="icon">📹</div>
            <div className="label">CCTV</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserLanding;
