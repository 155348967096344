import React from 'react';

const AuxiliaryServer = () => {
  return (
    <div className="server-logs">
      <p>AVAILABLE COMMANDS: view_logs, ping_devices, backup_data, check_status, back</p>
    </div>
  );
};

//if you're reading this YOU JUST LOST THE GAME

export default AuxiliaryServer;
