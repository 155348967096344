//get a job, ya lazy bum

import React, { useState } from 'react';
import './Careers.css';

function Careers() {
  const [openPosition, setOpenPosition] = useState(null);

  const togglePosition = (position) => {
    setOpenPosition(openPosition === position ? null : position);
  };

  return (
    <div className="careers-page">
      <h1>Join the Pioneers of Reality Manipulation</h1>
      <p>
        DEMIURGE INC. is at the forefront of cutting-edge research in reality manipulation and entropy reversal technology. We are seeking talented individuals to join our elite team. Below are our current openings:
      </p>

      <h2 onClick={() => togglePosition('leadScientist')} className="position-title">
        Lead Scientist in Entropy Reversal and Reality Manipulation
      </h2>
      {openPosition === 'leadScientist' && (
        <div className="position-details">
          <p>The ideal candidate will have a deep understanding of:</p>
          <ul>
            <li><strong>Quantum Entropy Modulation:</strong> Expertise in the modulation of quantum entropy states to induce targeted reversal of thermodynamic processes.</li>
            <li><strong>Chrono-Synchronicity Engineering:</strong> Advanced knowledge of chrono-synchronicity and its applications in the stabilization of parallel realities and time dilation phenomena.</li>
            <li><strong>Dimensional Flux Control:</strong> Proficiency in managing dimensional fluxes to maintain stability during reality shifts and prevent cascade failures.</li>
            <li><strong>Entropy Singularity Extraction:</strong> Experience in the extraction and harnessing of entropy singularities as a source of sustainable energy.</li>
            <li><strong>Multiversal Waveform Collapse:</strong> Familiarity with techniques to induce or prevent waveform collapse across multiple universes for controlled reality fragmentation.</li>
            <li><strong>Ontological Resonance Tuning:</strong> Skill in fine-tuning ontological resonances to align with desired outcomes across divergent timelines.</li>
            <li><strong>Reality Stabilization Protocols:</strong> Development and implementation of protocols to stabilize and reinforce the integrity of reality during high-risk manipulation procedures.</li>
          </ul>
          <p>
            This role offers the opportunity to work at the cutting edge of scientific discovery, contributing to research that has the potential to reshape entire universes. DEMIURGE INC. provides a collaborative environment where innovative thinking and technical excellence are valued and rewarded.
          </p>
        </div>
      )}

      <h2 onClick={() => togglePosition('softwareEngineer')} className="position-title">
        Senior Software Engineer/Neuroscientist
      </h2>
      {openPosition === 'softwareEngineer' && (
        <div className="position-details">
          <p>The ideal candidate will possess expertise in:</p>
          <ul>
            <li><strong>Reality Simulation Algorithms:</strong> Developing complex algorithms that simulate real-time reality manipulation scenarios.</li>
            <li><strong>Quantum Computing Integration:</strong> Implementing software solutions that leverage quantum computing for enhanced processing power.</li>
            <li><strong>Augmented Reality Systems:</strong> Creating and maintaining software for advanced implantable augmented reality applications used in experimental environments.</li>
            <li><strong>Data Security Protocols:</strong> Ensuring the highest level of security for sensitive research data through advanced encryption techniques.</li>
            <li><strong>Cross-Disciplinary Collaboration:</strong> Working closely with physicists, engineers, biologists, and other neuroscientists to develop integrated software solutions.</li>
          </ul>
          <p>
            This position is perfect for someone who thrives in a dynamic and challenging environment, where innovation and cutting-edge technology intersect. The successful candidate will play a crucial role in the development of software that supports our groundbreaking research.
          </p>
        </div>
      )}

      <h2 onClick={() => togglePosition('janitor')} className="position-title">
      Maintenance Technician
      </h2>
      {openPosition === 'janitor' && (
        <div className="position-details">
          <p>The ideal candidate will be responsible for:</p>
          <ul>
            <li><strong>Facility Maintenance:</strong> Ensuring the cleanliness and maintenance of all laboratories, offices, and common areas within our high-tech research facility.</li>
            <li><strong>Hazardous Waste Disposal:</strong> Safely managing and disposing of hazardous materials resulting from experimental procedures.</li>
            <li><strong>Equipment Cleaning:</strong> Regularly cleaning and sanitizing experimental equipment to maintain a sterile environment for research activities.</li>
            <li><strong>Security Clearance:</strong> Maintaining a high level of confidentiality and adhering to security protocols due to the sensitive nature of our work.</li>
            <li><strong>Attention to Detail:</strong> Ensuring that even the smallest maintenance issues are addressed promptly to maintain the integrity of our research environment.</li>
          </ul>
          <p>
            This position is critical to the smooth operation of our facility, ensuring that our scientists can focus on their groundbreaking work in a clean and safe environment. 
          </p>
        </div>
      )}

<div className="apply-button-container">
  <a 
    href="mailto:freakbait@freakbait.com?subject=Application for Operative Position at DEMIURGE INC&body=Thank%20you%20for%20your%20interest%20in%20joining%20DEMIURGE%20INC.%0D%0A%0D%0A%3D%3D%3D%3D%3D%3D%3D%3D%20APPLICATION%20FORM%20%3D%3D%3D%3D%3D%3D%3D%3D%0D%0A%0D%0A---%20PERSONAL%20INFORMATION%20---%0D%0AFull%20Name:%20%0D%0AOperating%20Alias%20(if%20applicable):%20%0D%0AEmail%20Address:%20%0D%0AContact%20Number:%20%0D%0A%0D%0A---%20QUALIFICATIONS%20---%0D%0ADegree(s)%20or%20Certifications%20in%20Experimental%20Technologies%20or%20Related%20Fields:%20%0D%0ASkills%20in%20Reality%20Manipulation,%20Dimensional%20Engineering,%20or%20Quantum%20Mechanics:%20%0D%0A%0D%0A---%20EXPERIMENTAL%20EXPERIENCE%20---%0D%0APrevious%20Experiments%20and%20Projects%20Conducted%20(at%20least%203%20examples):%20%0D%0ALevel%20of%20Comfort%20with%20Multiverse%20Exploration%20and%20Kugelblitz%20Phenomena:%20%0D%0A%0D%0A---%20ADDITIONAL%20INFORMATION%20---%0D%0AHave%20you%20ever%20been%20fractionated%20during%20an%20experiment?%20(yes%20or%20no)%20If%20yes,%20please%20provide%20details:%20%0D%0AWhat%20do%20you%20hope%20to%20accomplish%20by%20joining%20our%20reality-warping%20research?%20%0D%0A%0D%0A---%20REFERENCES%20---%0D%0AList%20at%20least%20two%20scientists%20or%20interdimensional%20entities%20who%20can%20vouch%20for%20your%20capabilities:%20"
    className="apply-button"
  >
    APPLY NOW
  </a>
</div>
<br />

      <p>
        If you are ready to take the next step in your career and join a team of world-renowned experts, we want to hear from you. Please send your CV and a detailed cover letter outlining your relevant experience to <a href="mailto:freakbait@freakbait.com">careers@demiurge-inc.com</a>.
      </p>
    </div>
  );
}

export default Careers;
