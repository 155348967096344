//get the fuck outta here

import React, { useState, useEffect, useRef } from 'react';
import QuantumHarmonics from './QuantumHarmonics';
import './ConsoleGame.css';

const AdminPanel = ({ onCompleteCommand, exitAdminMode }) => {
  const [adminInput, setAdminInput] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus(); 
  }, []);

  const handleAdminCommand = () => {
    if (adminInput.trim()) {
      if (adminInput === 'view_admin_logs') {
        onCompleteCommand([
          'Accessing Admin Logs...',
          'CORRUPTED DATA'
        ]);
      } else if (adminInput === 'adjust_harmonics') {
        onCompleteCommand([
          <QuantumHarmonics
            onComplete={() => {
              onCompleteCommand(['SUCCESS. HARMONIC FREQUENCIES STABILIZED.']);
            }}
          />
        ]);
      } else if (adminInput === 'back') {
        exitAdminMode();
      } else if (adminInput === 'clear') {
        onCompleteCommand(['Terminal cleared.']);
        setTimeout(() => onCompleteCommand([]), 100); 
      } else {
        onCompleteCommand(['Unknown admin command.']);
      }
      setAdminInput('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAdminCommand();
    }
  };

  return (
    <div className="terminal-input-wrapper">
      <input
        type="text"
        className="terminal-input"
        value={adminInput}
        onChange={(e) => setAdminInput(e.target.value)}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        placeholder="Type admin command..."
      />
    </div>
  );
};

export default AdminPanel;
