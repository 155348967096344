//if you're reading this YOU JUST LOST THE GAME

import React, { useState, useEffect } from 'react';

const PingDevices = ({ serverType, onComplete }) => {
  const getPingLines = (serverType) => {
    switch (serverType) {
      case 'central_server':
        return [
          "Pinging devices connected to the Central Server...",
          "Device 1: [Mainframe Node] - Response: ONLINE",
          "Device 2: [Research Subnet Controller] - Response: ONLINE",
          "Device 3: [Voidspace Monitoring Unit] - Response: ONLINE, Latency: 450ms",
          "Device 4: [Security Node Alpha] - Response: OFFLINE",
          "Device 5: [Auxiliary Data Storage] - Response: ONLINE, Data Integrity: WARNING",
          "Device 6: [Experiment Node 31] - Response: ONLINE",
          "Device 7: [Experiment Node 44] - Response: Latency Error: High response time detected (700ms)",
          "Device 8: [Voidspace Safety Unit] - Response: OFFLINE",
        ];
      case 'auxiliary_server':
        return [
          "Pinging devices connected to the Auxiliary Server...",
          "Device 1: [Storage Node Alpha] - Response: ONLINE",
          "Device 2: [Backup Node Beta] - Response: ONLINE",
          "Device 3: [Experiment Node 903] - Response: Latency Error: High response time detected (900ms)",
          "Device 4: [Data Transfer Node] - Response: OFFLINE",
          "Device 5: [Auxiliary Data Storage] - Response: ONLINE, Data Integrity: WARNING",
          "Device 6: [Voidspace Monitoring Unit] - Response: Latency Error: 650ms, Possible interference",
          "Device 7: [Quantum Collapse Generator] - Response: ERROR [UNSTABLE ENERGY LEVELS DETECTED]",
          "Device 8: [Cross-Dimensional Tethering Node] - Response: OFFLINE, Manual reboot required",
          "Device 9: [Reality Stabilization Anchor] - Response: ONLINE, Stable",
        ];
      case 'DEFUNCT':
        return [
          "[DEFUNCT] DEVICE(S) DISCONNECTED",
          "UNABLE TO PING"
        ];
      default:
        return [];
    }
  };

  //if you're reading this YOU JUST LOST THE GAME

  const pingLines = getPingLines(serverType);
  const [pingIndex, setPingIndex] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (pingIndex < pingLines.length) {
      const timeout = setTimeout(() => {
        setPingIndex(pingIndex + 1);
      }, 600);
      return () => clearTimeout(timeout);
    } else if (!isComplete) {
      setIsComplete(true);
      onComplete(); 
    }
  }, [pingIndex, pingLines.length, isComplete, onComplete]);

  return (
    <>
      {pingLines.slice(0, pingIndex).map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </>
  );
};

export default PingDevices;
