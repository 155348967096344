//what're you doing digging around here? 
//you wanna open up the terminal on the website without having to dig through the code?
//if you're on mobile, just hold your finger on the botton fifth screen for three seconds
//if you're on desktop, just enter 00000 on your keyboard
//and how about you just play the game rather than dig through my shitty code?

import React, { useState, useEffect, useRef } from 'react';
import './TerminalModal.css';
import ConsoleGame from './ConsoleGame';

function TerminalModal({ isOpen, onClose }) {
  const [tapHoldTriggered, setTapHoldTriggered] = useState(false);
  const [keySequence, setKeySequence] = useState([]);
  const timerRef = useRef(null);


  const handleTouchStart = () => {
    timerRef.current = setTimeout(() => {
      setTapHoldTriggered(true);
      onOpen();
    }, 3000); 
  };

  const handleTouchEnd = () => {
    clearTimeout(timerRef.current); 
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      setKeySequence((prev) => [...prev, e.key]);

      if (keySequence.join('').includes('00000')) {
        onOpen(); 
        setKeySequence([]);
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [keySequence]);

  const onOpen = () => {
    setTapHoldTriggered(true);
  };

  return (
    <>
      <div
        className="touch-area"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
      </div>

      {isOpen || tapHoldTriggered ? (
        <div className="terminal-modal">
          <div className="terminal-header">
            <h4>Console</h4>
            <button
              onClick={() => {
                setTapHoldTriggered(false);
                onClose();
              }}
              className="terminal-close-btn"
            >
              ✕
            </button>
          </div>
          <div className="terminal-content">
            <ConsoleGame />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default TerminalModal;
