import React, { useState, useEffect } from 'react';
import './QuantumHarmonics.css';

//if you're reading this YOU JUST LOST THE GAME

const QuantumHarmonics = ({ onComplete }) => {
  const [harmonics, setHarmonics] = useState({
    lowFrequency: 0,
    midFrequency: 0,
    highFrequency: 0,
  });

  const [stabilized, setStabilized] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [stabilizationTimeout, setStabilizationTimeout] = useState(null);

  const correctFrequencies = {
    lowFrequency: 69,
    midFrequency: 420,
    highFrequency: 911,
  };

  const handleHarmonicChange = (e, freqType) => {
    const value = parseInt(e.target.value, 10);
    setHarmonics((prev) => ({
      ...prev,
      [freqType]: value,
    }));
  };

  const checkStabilization = () => {
    const isStabilized =
      harmonics.lowFrequency === correctFrequencies.lowFrequency &&
      harmonics.midFrequency === correctFrequencies.midFrequency &&
      harmonics.highFrequency === correctFrequencies.highFrequency;

    if (isStabilized) {
      const timeout = setTimeout(() => {
        setStabilized(true);  
        setTimeout(() => {
          setCollapsed(true);  
          if (typeof onComplete === 'function') {
            onComplete(); 
          }
        }, 2000);
      }, 2000);

      setStabilizationTimeout(timeout);
    } else {
      clearTimeout(stabilizationTimeout);
      setStabilized(false);
    }
  };

  useEffect(() => {
    checkStabilization();
    return () => {

      clearTimeout(stabilizationTimeout);
    };
  }, [harmonics]); 

  const getBackgroundColor = (value, target) => {
    const difference = Math.abs(value - target);
    if (difference === 0) return '#0096FF';
    if (difference <= 20) return '#7ed03b';
    if (difference <= 50) return '#ffeb3b';
    if (difference <= 150) return '#ffa500'; 
    return '#f44336'; 
  };

  const isCorrectFrequency = (value, target) => value === target;

  if (collapsed) {
    return (
      <div className="harmonic-success">
        <p className="success-message">✔ Harmonic Frequencies Stabilized</p>
      </div>
    );
  }

  //if you're reading this YOU JUST LOST THE GAME

  return (
    <div className="quantum-harmonics">
      <h3>QUANTUM HARMONIC ADJUSTMENT</h3>

      <div className="slider-control">
        <label>Low Frequency: {harmonics.lowFrequency} Hz</label>
        <input
          type="range"
          min="0"
          max="1000"
          value={harmonics.lowFrequency}
          onChange={(e) => handleHarmonicChange(e, 'lowFrequency')}
          className={isCorrectFrequency(harmonics.lowFrequency, correctFrequencies.lowFrequency) ? 'pulsing-slider' : ''}
          style={{ background: getBackgroundColor(harmonics.lowFrequency, correctFrequencies.lowFrequency) }}
        />
      </div>

      <div className="slider-control">
        <label>Mid Frequency: {harmonics.midFrequency} Hz</label>
        <input
          type="range"
          min="0"
          max="1000"
          value={harmonics.midFrequency}
          onChange={(e) => handleHarmonicChange(e, 'midFrequency')}
          className={isCorrectFrequency(harmonics.midFrequency, correctFrequencies.midFrequency) ? 'pulsing-slider' : ''}
          style={{ background: getBackgroundColor(harmonics.midFrequency, correctFrequencies.midFrequency) }}
        />
      </div>

      <div className="slider-control">
        <label>High Frequency: {harmonics.highFrequency} Hz</label>
        <input
          type="range"
          min="0"
          max="1000"
          value={harmonics.highFrequency}
          onChange={(e) => handleHarmonicChange(e, 'highFrequency')}
          className={isCorrectFrequency(harmonics.highFrequency, correctFrequencies.highFrequency) ? 'pulsing-slider' : ''}
          style={{ background: getBackgroundColor(harmonics.highFrequency, correctFrequencies.highFrequency) }}
        />
      </div>

      {stabilized ? (
        <p className="status success">EXPERIMENT STABILIZED</p>
      ) : (
        <p className="status error">EXPERIMENT UNSTABLE. ADJUSTMENTS REQUIRED.</p>
      )}
    </div>
  );
};

export default QuantumHarmonics;
