import React from 'react';

const ViewLogs = ({ serverType }) => {
  const centralServerLogs = (
    <div>
      <p>LOG 1:</p>
      <p>[XX/XX/XXXX XX:XX] - Warning: Voidspace Subnet experiencing signal instability.</p>
      <p>- Experimental anomalies detected.</p>
      <p>- Re-routing all outgoing network traffic to central firewall.</p>
      <p>- Research team notified: Dr. Raymond Baker, Dr. Evelyn Strauss.</p>
      <p>- Security Override: A4H-983. Access restricted to senior personnel only.</p><br />

      <p>LOG 2:</p>
      <p>[XX/XX/XXXX XX:XX] - Unauthorized access attempt detected on Central Server.</p>
      <p>- Source: 192.168.1.10 (Research Subnet)</p>
      <p>- Failed Login Attempts: 13 (from user: rbaker)</p>
      <p>- Initiating lockdown of sensitive files.</p>
      <p>- Alert sent to system administrator: nmanhav.</p>
      <p>- Security Response Initiated: Protocol Echo-5.</p><br />
      
      {/* add future logs as ARG develops */}
    </div>
  );

  const defunctServerLogs = (
    <div>
      <p>LOG 1:</p>
      <p>[XX/XX/XXXX XX:XX] - Log data corrupted.</p>
      <p>LOG 2:</p>
      <p>[XX/XX/XXXX XX:XX] - Partial log recovered: "...protocol failure imminent..."</p>
      <p>LOG 3:</p>
      <p>[XX/XX/XXXX XX:XX] - Data stream interrupted. Cause unknown.</p><br />

      {/* add future logs as ARG develops */}
    </div>
  );

  const auxiliaryServerLogs = (
    <div>
      <p>LOG 1:</p>
      <p>[XX/XX/XXXX XX:XX] - Backup of Experiment 478(a) successful. Data integrity verified.</p>
      <p>- Subject: Arctic Fungal Extremophile Discovery.</p>
      <p>- Additional Notes: Integration of Phase Matter Transition Study by Dr. Orion Vale.</p><br />

      <p>LOG 2:</p>
      <p>[XX/XX/XXXX XX:XX] - Transfer initiated for voidspace anomaly data.</p>
      <p>- Data Source: Voidspace Monitoring Unit.</p>
      <p>- Status: Partial upload (72% complete).</p>
      <p>- Researcher Notification: Dr. Nicholas Manhav, Dr. Evelyn Strauss.</p><br />
      
      {/* add future logs as ARG develops */}
    </div>
  );

  const renderLogs = () => {
    switch (serverType) {
      case 'central_server':
        return centralServerLogs;
      case 'DEFUNCT':
        return defunctServerLogs;
      case 'auxiliary_server':
        return auxiliaryServerLogs;
      default:
        return <p>No logs available for this server.</p>;
    }
  };

  return (
    <div className='server-logs'>
      {renderLogs()}
    </div>
  );
};

export default ViewLogs;
