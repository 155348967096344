import React, { useState, useEffect } from 'react';

const ScanSubnets = ({ onComplete }) => {
  const [scanResults, setScanResults] = useState([]);
  const [index, setIndex] = useState(0);

  const subnetData = [
    {
      name: "Voidspace Subnet",
      status: "ONLINE",
      latency: Math.floor(Math.random() * 500 + 100),
      warning: "Unusual data packet activity detected."
    },
    {
      name: "Research Subnet",
      status: "ONLINE",
      latency: Math.floor(Math.random() * 500 + 100)
    },
    {
      name: "Security Subnet",
      status: "OFFLINE",
      error: "No response from security nodes."
    },
    {
      name: "Auxiliary Subnet",
      status: "ONLINE",
      capacity: `${Math.floor(Math.random() * 50 + 50)}% full`
    }
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      if (index < subnetData.length) {
        setScanResults((prevResults) => [...prevResults, subnetData[index]]);
        setIndex((prevIndex) => prevIndex + 1);
      } else if (onComplete) {
        onComplete();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [index, onComplete]);

  //if you're reading this YOU JUST LOST THE GAME

  return (
    <div>
      <p>Scanning subnets connected to Central Server...</p>
      {scanResults.map((subnet, i) => (
        <div key={i}>
          <p>Subnet: [{subnet.name}] - Response: {subnet.status}</p>
          {subnet.latency && <p>- Latency: {subnet.latency}ms</p>}
          {subnet.warning && <p>- Warning: {subnet.warning}</p>}
          {subnet.error && <p>- Error: {subnet.error}</p>}
          {subnet.capacity && <p>- Data Capacity: {subnet.capacity}</p>}
        </div>
      ))}
      {index >= subnetData.length && <p>End of subnet scan.</p>}
    </div>
  );
};

export default ScanSubnets;
