//if you're reading this YOU JUST LOST THE GAME

import React from 'react';

const MainContent = ({ pageContent, activePage }) => (
  <div className="main-content">
    <div>
      <h2>{pageContent[activePage]?.title || 'Page Not Found'}</h2>
      {pageContent[activePage]?.content || <p>No content available.</p>}
    </div>
  </div>
);

export default MainContent;
