//what're you doing in here? looking for a password or an easter egg or something?


import React from 'react';
import './Modal.css';

function Modal({ isOpen, onClose, content, glitch }) {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className={`modal-content ${glitch ? 'modal-glitch' : ''}`}>
        {/* <button className="modal-close-top-right" onClick={onClose}>✕</button> */}
        <div className="modal-body" onClick={onClose}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  );
}

export default Modal;
