//get the fuck outta here, bro

import React from 'react';

const CentralServer = () => {
  return (
    <div className="server-logs">
      <p>AVAILABLE COMMANDS: view_logs, ping_devices, scan_subnets, back</p>
    </div>
  );
};

export default CentralServer;