//what're you doing digging around here? 
//you wanna open up the console on the website without having to dig through the code?
//if you're on mobile, just hold your finger on the botton fifth screen for three seconds
//if you're on desktop, just enter 00000 on your keyboard
//and how about you just play the game rather than dig through my shitty code?

import React, { useState, useEffect, useRef } from 'react';
import TerminalIntro from './TerminalIntro';
import CentralServer from './CentralServer';
import DefunctServer from './DefunctServer';
import AuxiliaryServer from './AuxiliaryServer';
import PingDevices from './PingDevices';
import TraceIP from './TraceIP';
import ScanSubnets from './ScanSubnets';
import ViewLogs from './ViewLogs';
import QuantumHarmonics from './QuantumHarmonics';
import './ConsoleGame.css';
import AdminPanel from './AdminPanel';

const ConsoleGame = ({ hasAdminPermissions }) => {
  const [input, setInput] = useState('');
  const [history, setHistory] = useState([{ system: <TerminalIntro /> }]);
  const [currentState, setCurrentState] = useState('network_start');
  const [stateStack, setStateStack] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const outputRef = useRef(null);
  const inputRef = useRef(null);
  const bottomRef = useRef(null);

  const handleCommand = () => {
    if (input.trim()) {
      setHistory((prevHistory) => [...prevHistory, { user: input }]);
      processCommand(input);
      setInput('');
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history]);

  const handleAdminComplete = (output) => {
    setHistory((prevHistory) => [
      ...prevHistory,
      ...output.map((line) => ({ system: line })),
    ]);
  };

  const exitAdminMode = () => {
    setIsAdmin(false);
    setHistory((prevHistory) => [
      ...prevHistory,
      { system: 'Exiting Admin Mode. Returning to normal terminal.' },
    ]);
  };

//if you're reading this, you just LOST THE GAME

  const commandHandlers = {
    ping_devices: () => {
      setHistory((prevHistory) => [
        ...prevHistory,
        {
          system: (
            <PingDevices
              className="ping"
              serverType={currentState}
              onComplete={() =>
                setHistory((prevHistory) => [
                  ...prevHistory,
                  { system: 'Ping complete' },
                ])
              }
            />
          ),
        },
      ]);
    },

    trace: (target) => {
      setHistory((prevHistory) => [
        ...prevHistory,
        {
          system: (
            <TraceIP
              target={target}
              onComplete={() =>
                setHistory((prevHistory) => [
                  ...prevHistory,
                  { system: `Trace to ${target} complete` },
                ])
              }
            />
          ),
        },
      ]);
    },

    scan_subnets: () => {
      setHistory((prevHistory) => [
        ...prevHistory,
        {
          system: (
            <ScanSubnets
              onComplete={() =>
                setHistory((prevHistory) => [
                  ...prevHistory,
                  { system: 'Subnet scan complete' },
                ])
              }
            />
          ),
        },
      ]);
    },

    connect: (server) => {
      setStateStack((prevStack) => [...prevStack, currentState]);
      let serverComponent;

      switch (server) {
        case 'central_server':
          serverComponent = <CentralServer />;
          break;
        case 'DEFUNCT':
          serverComponent = <DefunctServer />;
          break;
        case 'auxiliary_server':
          serverComponent = <AuxiliaryServer />;
          break;
        default:
          setHistory((prevHistory) => [
            ...prevHistory,
            { system: `Error: Unable to connect to ${server}` },
          ]);
          return;
      }

      setHistory((prevHistory) => [
        ...prevHistory,
        { system: `Connecting to ${server}... CONNECTION ESTABLISHED` },
        { system: serverComponent },
      ]);
      setCurrentState(server);
    },

    admin_login: (poopword) => {
      if (poopword === '12345') {
        setIsAdmin(true);
        setHistory((prevHistory) => [
          ...prevHistory,
          { system: 'Admin login successful' },
          { system: 'AVAILABLE COMMANDS: view_admin_logs, adjust_harmonics, back' },
        ]);
      } else {
        setHistory((prevHistory) => [
          ...prevHistory,
          { system: 'ACCESS DENIED.' },
        ]);
      }
    },

    view_logs: () => {
      setHistory((prevHistory) => [
        ...prevHistory,
        { system: <ViewLogs serverType={currentState} /> },
      ]);
    },

    backup_data: () => {
      setHistory((prevHistory) => [
        ...prevHistory,
        { system: 'Starting backup of auxiliary server data...' },
      ]);
      setTimeout(() => {
        setHistory((prevHistory) => [
          ...prevHistory,
          { system: 'Backup completed successfully. Data transferred to secure storage.' },
        ]);
      }, 3000);
    },

    system_status: () => {
      setHistory((prevHistory) => [
        ...prevHistory,
        { system: 'Checking system status...' },
      ]);
      setTimeout(() => {
        setHistory((prevHistory) => [
          ...prevHistory,
          { system: '> SYSTEM STATUS:' },
          { system: ' - Subnet Integrity: Nominal' },
          { system: ' - Data Link: Stable' },
          { system: ' - Quantum Collapse Generator: Operational' },
          { system: ' - Entropy Stabilizer: Online (Warning: Minor fluctuations detected)' },
          { system: '> SECURITY LEVEL: Alpha-9' },
          { system: ' - Multi-dimensional access restricted' },
          { system: ' - Last security breach: [XX/XX/XXXX] (see log 478A)' },
        ]);
      }, 200);
    },
    
//if you're reading this, you just LOST THE GAME

    check_status: () => {
      setHistory((prevHistory) => [
        ...prevHistory,
        { system: 'Checking auxiliary server status...' },
      ]);
      setTimeout(() => {
        setHistory((prevHistory) => [
          ...prevHistory,
          { system: 'STATUS: ONLINE' },
          { system: 'UPTIME: 48 days, 12 hours, 36 minutes' },
          { system: 'DATA INTEGRITY: 99.7%' },
          { system: 'LAST BACKUP: 3 hours ago' },
        ]);
      }, 1500);
    },

    restore: () => {
      setHistory((prevHistory) => [
        ...prevHistory,
        { system: 'Attempting to restore [DEFUNCT] server...' },
      ]);
      setTimeout(() => {
        setHistory((prevHistory) => [
          ...prevHistory,
          { system: 'Error: Insufficient permissions. Manual reboot required.' },
        ]);
      }, 2500);
    },

    back: () => {
      if (stateStack.length > 0) {
        const previousState = stateStack.pop();
        setHistory((prevHistory) => [
          ...prevHistory,
          { system: `Returning to ${previousState}...` },
        ]);
        setCurrentState(previousState);
      } else {
        setHistory((prevHistory) => [
          ...prevHistory,
          { system: 'Error: No previous state to return to.' },
        ]);
      }
    },

    clear: () => {
      setHistory([]); 
    },

    help: () => {
      const helpMessages = {
        network_start: [
          'Available commands: ping_devices, trace [target], connect [server], help',
          'Trace options: [gateway], [controller], [database_node], [quantum_research], [experiment_subnet], [admin]',
          'Connect options: [central_server], [DEFUNCT], [auxiliary_server]',
        ],
        central_server: ['Available commands: view_logs, ping_devices, scan_subnets, back'],
        DEFUNCT: ['Available commands: view_logs, ping_devices, restore, back'],
        auxiliary_server: ['Available commands: view_logs, ping_devices, backup_data, check_status, back'],
      };

      setHistory((prevHistory) => [
        ...prevHistory,
        { system: helpMessages[currentState].map((line, index) => <p key={index}>{line}</p>) },
      ]);
    },
  };

  const processCommand = (command) => {
    const [cmd, ...args] = command.split(' ');
    const arg = args[0];

    const handler = commandHandlers[cmd.toLowerCase()];
    if (handler) {
      handler(arg);
    } else {
      setHistory((prevHistory) => [
        ...prevHistory,
        { system: `Unknown command: ${cmd}` },
      ]);
    }
  };

  return (
    <div className="console-game">
      <div className="console-output" ref={outputRef}>
        {history.map((entry, index) =>
          entry.user ? <p key={index}>&gt; {entry.user}</p> : <p key={index}>{entry.system}</p>
        )}
        <div ref={bottomRef} />
      </div>

      {isAdmin ? (
        <AdminPanel onCompleteCommand={handleAdminComplete} exitAdminMode={exitAdminMode} />
      ) : (
        <div className="terminal-input-wrapper">
          <input
            type="text"
            className="terminal-input"
            value={input}
            ref={inputRef}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleCommand()}
            placeholder="Type a command..."
          />
        </div>
      )}
    </div>
  );
};

export default ConsoleGame;
