//welcome to DEMIURGE.COM
//this part of the ARG isn't intended to be analyzed via my shitty code
//if you're digging through the code to solve the narrative I've built
//it'll be like dissecting a frog
//sure, you understand how it works - but now the fucking frog is dead
//this website is meant to be used like normal front facing website
//(because I am a novice coder who sucks at building a backend - for now)
//but since you're here already
//I might as well just tell you 
//that me and your mom have really been hitting it off 
//and I think we're ready to take it to the next level
//love (your new stepdad) FREAKBAiT


import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import PageContent from './components/PageContent';
import Modal from './components/Modal';
import users from './users';
import FO from './img/fo.webp';
import admincctvImages from './data/admincctvImages';
import ConsoleGame from './components/ConsoleGame';
import TerminalModal from './components/TerminalModal';



function App() {
  const [inputUsername, setInputUsername] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState('');
  const [activePage, setActivePage] = useState('home');
  const [fullName, setFullName] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarShrunk, setIsSidebarShrunk] = useState(true);
  const [glitch, setGlitch] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({ experiments: [], email: [], files: [], cctvImages: [] });
  const [emailSelected, setEmailSelected] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState('');
  const [showConsoleGame, setShowConsoleGame] = useState(false);
  const [theme, setTheme] = useState('light');
  const [isTerminalOpen, setIsTerminalOpen] = useState(false);
  const [hasAdminPermissions, setHasAdminPermissions] = useState(false);

//if you're reading this, you just LOST THE GAME
  
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light'; // 
    setTheme(savedTheme); 
    document.body.className = savedTheme; 
  }, []);
  
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    setTheme(savedTheme);
    document.body.className = savedTheme;
    console.log('Current theme on load:', savedTheme); 
  }, []);
  
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    console.log('Switching to:', newTheme); 
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.body.className = newTheme;
  };
  
  let keys = [];
  let timer;
  
  const funWord = "00000";
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      console.log("Key pressed:", e.key);
  
      keys.push(e.key);
  

      if (keys.length > 5) {
        keys.shift();
      }
  
        console.log("Current sequence:", keys.join(''));
      if (keys.join('') === funWord ) {
        console.log("Success!");
        setIsTerminalOpen(true); 
        keys = []; 
        clearTimeout(timer);
      }

      clearTimeout(timer);
      timer = setTimeout(() => {
        console.log("Timer expired. Resetting key sequence.");
        keys = [];
      }, 3000);
    };
  
    window.addEventListener("keydown", handleKeyDown);
  
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  

  useEffect(() => {
    const startRandomGlitch = () => {
      const randomDelay = Math.floor(Math.random() * 15000) + 10000; 
      const glitchDuration = Math.floor(Math.random() * 500) + 100; 
      setTimeout(() => {
        setGlitch(true);
        setTimeout(() => {
          setGlitch(false); 
        }, glitchDuration);
        startRandomGlitch(); 
      }, randomDelay);
    };

    if (isModalOpen) {
      startRandomGlitch(); 
    }

    return () => {
      clearTimeout();
    };
  }, [isModalOpen]);

  const toggleSidebar = () => {
    setIsSidebarShrunk(!isSidebarShrunk);
  };

  //if you're reading this, you just LOST THE GAME AGAIN

  const loadData = async (path) => {
    try {
      console.log(`Loading data from ./data/${path}Experiments.js`);
      const experimentsModule = await import(`./data/${path}Experiments.js`);
      const emailModule = await import(`./data/${path}Email.js`);
      const filesModule = await import(`./data/${path}Files.js`);

      setCurrentUserData(prevData => ({
        ...prevData,
        experiments: experimentsModule.default || [],
        email: emailModule.default || [],
        files: filesModule.default || [],
        cctvImages: admincctvImages,
      }));
    } catch (error) {
      console.error("Failed to load data for:", path, error);
      setCurrentUserData({ experiments: [], email: [], files: [] });
    }
  };

  const handleNavigation = (page) => {
    setActivePage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (window.innerWidth <= 768) {
      setIsSidebarShrunk(true);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const user = users[inputUsername];
    if (user && inputPassword === user.password) {
      setLoggedIn(true);
      setFullName(user.fullName);
      setCurrentUserRole(user.role);
      setActivePage('user-landing');
      loadData(user.dataPath);
      setError('');

      if (inputUsername === 'admin') {
        setHasAdminPermissions(true);  
        console.log('Admin permissions granted.');
      } else {
        setHasAdminPermissions(false);
      }
      

      if (window.innerWidth <= 768) {
        setIsSidebarShrunk(true);
      }
    } else {
      setError('Incorrect username or password');
    }
  };

  //if you're reading this, you just LOST THE GAME AGAIN

  const handleOpenAdminTerminal = () => {
    setIsTerminalOpen(true);
  
    if (hasAdminPermissions) {
      console.log('Admin terminal opened with permissions:', hasAdminPermissions);
    }
  };


  const handleLogout = () => {
    setLoggedIn(false);
    setActivePage('home');
    setInputUsername('');
    setInputPassword('');
    setCurrentUserData({ experiments: [], email: [] });
  };

  const [currentEmail, setCurrentEmail] = useState(null);
  const handleEmailSelect = (log) => {
    setCurrentEmail(log);
    setEmailSelected(true);
  };

  const handleBackToList = () => {
    setEmailSelected(false);
  };

 
  const pageContent = PageContent(
    setActivePage,
    currentUserData,
    setModalContent,
    setIsModalOpen,
    emailSelected,
    handleEmailSelect,
    currentEmail,
    handleBackToList,
    fullName,
    inputUsername, 
    users[inputUsername]?.role 
  );

  const openConsoleModal = () => {
    setShowConsoleGame(true); 
    setIsModalOpen(true); 
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setShowConsoleGame(false); 
  };

  const openTerminal = () => {
    setIsTerminalOpen(true); 
  };

  const closeTerminal = () => {
    setIsTerminalOpen(false); 
  };

  return (
    <div className={`App ${theme}`}>
      <Header toggleSidebar={toggleSidebar} />
      <div className="menu-bar" onClick={toggleSidebar}>
        <p>Menu</p>
      </div>


      <Sidebar
        isSidebarShrunk={isSidebarShrunk}
        handleNavigation={handleNavigation}
        loggedIn={loggedIn}
        users={users}
        inputUsername={inputUsername}
        setInputUsername={setInputUsername}
        setInputPassword={setInputPassword}
        handleLogout={handleLogout}
        toggleSidebar={toggleSidebar}
        error={error}
        handleLogin={handleLogin}
        theme={theme} 
        toggleTheme={toggleTheme} 
        openConsoleModal={openConsoleModal}
        openTerminal={openTerminal}
      />

      <MainContent
        pageContent={pageContent}
        activePage={activePage}
      />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={modalContent}
        glitch={glitch}
      />

      {/* Terminal Modal */}
      <TerminalModal isOpen={isTerminalOpen} onClose={closeTerminal}>
          <ConsoleGame hasAdminPermissions={hasAdminPermissions} />      
      </TerminalModal>



    </div>
  );
}

//if you're reading this, you just LOST THE GAME AGAIN
//holy shit dude, you're on a roll

export default App;
